body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.stripe {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  -webkit-transform: skewY(-8deg); 
  transform: skewY(-8deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  /* background: linear-gradient(150deg,#53f 15%,#05d5ff 70%,#a6ffcb 94%) */
  /* background: linear-gradient(150deg,rgb(30, 136, 229) 15%,#05d5ff 70%,#a6ffcb 94%) */
  background: linear-gradient(150deg,#DF620D 15%,#FFAC3B 70%,#FFE596 94%)
  /* background: linear-gradient(150deg,#FFE596 15%,#FFAC3B 70%,#DF620D 94%); */

  
  
  /* background: linear-gradient(150deg,#a6ffcb 15%,#05d5ff 60%,#53f 100%) */
}

.flags-strip {
  display: inline-block; 
  margin-left: 6px;
  width:80px;
  height: 60px
}
.inputNumber:focus {
  outline: none

}

.inputNumber {
  -webkit-appearance: none;
  font-size: 20px;
  width: 30px;
  text-align: center;
  padding-bottom: 0px;
  height: 30px;  
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #999;
  padding: 0px
}

.homePage {

  position: relative;
  text-align: center;
  padding-Bottom: 500px;
  overflow: hidden;

}
.hero {
  width: 80%;
  margin: auto ;
  margin-top: 100px;
  display: inline-block;
}

ul {
  padding-left: 20px;
}

.regulamento {
  font-family: Open Sans;
  margin-left: 30px;
  width: 85%;
}

.regulamento p,li{
  font-size: 18px 
}

.sticky-table-column {
  width: unset;
}
.sticky-table-corner {
  width: unset;
}
.checkers {
  background-color: #ddd;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='7' viewBox='0 0 8 8'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E"); */
}
.checkers .odd {
  background-color: #d5d5d5;
}

.player-row:last-child {
  padding-bottom: 50px
}

.nameColumn {
  background-color: #eee;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='7' viewBox='0 0 8 8'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.nameSize {
  width: 170px;
  overflow: hidden;

}

.ptsColumn {
  left: 135px;
  position: absolute;
  width: 45px;
  text-align: right;
}



.column {
  display: inline-block
  
}

.tabGame {
  position: relative;
  height: 50px;
}

.gameName { 
  position: absolute;
  top: 0px;
  left: 0px; 
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
}

.gameEmail {
  position: absolute;
  left: 0px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 8px;
}
.gameUserId {
  position: absolute;
  left: 150px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 8px;
}
.gameTransactionId {
  position: absolute;
  left: 300px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 8px;
}
.gameId {
  position: absolute;
  left: 400px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 8px;
}

.results {
  position: absolute;
  left: 500px;
  top: 0px;
  font-family: Lato

}


.homeResult, .awayResult, .pts {
  width: 12px;
  font-size: 12px
}



.vertical {
  writing-mode: vertical-rl; 
  /* text-orientation: upright; */
  vertical-align: top;
  height: 80px;
}

.tabHeader {
  margin-top: 5px;
  height: 80px;
  position: fixed;
  width: 2300px;
}

.tabContainer {
  width: 2300px
}

.tabContent {
  margin-top: 80px;
}

.sticky-table-header-wrapper { 
  position: relative;
  padding-left: 25px;
  background-color: white;
}

.sticky-table-header-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
}

.degrade {
  background: linear-gradient(150deg,rgb(30, 136, 229) 5%,rgb(255, 64, 129) 95%);
  padding-left: 25px;
}

.sidebar {
  position: absolute;
  margin-left: -25px;
  width: 25px;
  height: 60px;
}

.sidebar.odd {
  background-color: rgba(255,255,255,0.2) !important;
}

.mygames-row:last-child {
  padding-bottom: 15px
}

.whitebar {
  position: relative;
  /* background: white;  */
  /* height: 80px; */

}

.whitebar:after {
 content: "";
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 z-index: 1;
 box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);

}


.sticky-table-column {
  /* background-image:url(background_gs.svg); */
  /* background: linear-gradient(150deg,rgb(30, 136, 229) 15%,#05d5ff 70%,#a6ffcb 94%); */
  /* background: linear-gradient(150deg,rgb(30, 136, 229) 15%,#05d5ff 70%,#a6ffcb 94%); */
  /* background: linear-gradient(150deg,#DF620D 15%,#FFAC3B 70%,#FFE596 94%); */
  /* background: linear-gradient(150deg,#FFE596 15%,#FFAC3B 70%, #DF620D 94%); */
  /* background: linear-gradient(150deg,#fedf00 15%,#009b3a 70%, rgb(30, 136, 229) 94%); */
  /* background: linear-gradient(150deg,black 15%,#009b3a 70%, rgb(30, 136, 229) 94%); */
  /* background: linear-gradient(150deg,white 15%, rgb(30, 136, 229) 94%); */
  /* background: linear-gradient(150deg,white 15%,rgb(77, 208, 225) rgb(30, 136, 229) 94%); */
  /* background: linear-gradient(150deg,rgb(30, 136, 229) 20%, rgb(77, 208, 225) 50%, #eee 90%); */
  background: linear-gradient(150deg,rgb(30, 136, 229) 5%,rgb(255, 64, 129) 95%);

  

  background-color: none !important;
  padding-left: 24px;
  /* border-right: 1px solid rgb(30, 136, 229); */
  padding-right: 1px;
  color: #333;
  /* color: white */
}

.sticky-table-x-wrapper {
  background-color:white;
}

.row-0 {
  background-color: #f6f6f6;
}



.abutton  {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  -webkit-box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .025em;
  color: #6772e5;
  text-decoration: none;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
  /* background: #3ecf8e; */
  text-shadow: 0 1px 3px rgba(36,180,126,.4);
  background: linear-gradient(150deg,rgb(30, 136, 229) 15%,#05d5ff 70%,#a6ffcb 94%)
  

  /* background: linear-gradient(150deg,#DF620D 15%,#FFAC3B 70%,#FFE596 94%) */


  
}


.arrow {
  background: url(arrow.svg) no-repeat top left; 
  background-size: contain;
  width: 40px;
  height: 40px;
}

.position {
 color: white;
 position: absolute;
 top: 24px;
 margin-left: -22px;
 font-family: Lato;
 font-size: 15px;
 text-align: right;
 display: inline-block;
 width: 20px;
 transform: rotate(270deg); 
}


.position.onedigit {
  transform: none; 
  top: 9px;
  margin-left: -24px;
}

.position.twodigits {margin-left: -24px; top: 18px}

.position sup{
  font-size: 10px;
  /* margin-top: -2px; */
  font-weight: normal !important; 
}


.position.pins {
  top: 29px;
  font-size: 15px;

}
.position.pins {top:24px;}
.position.pins.onedigit {top:9px;}
.position.pins.twodigits {top:14px;}


.ranking-pin {
  font-size: 20px !important;
  color: rgb(150,150,150) !important;
  cursor: pointer;
  margin-top: -12px;
}

.ranking-pin-white {
  font-size: 20px !important;
  color: white !important;
  cursor: pointer;
  margin-top: -12px;
  
}