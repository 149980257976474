body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.stripe {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  -webkit-transform: skewY(-8deg); 
  transform: skewY(-8deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  /* background: linear-gradient(150deg,#53f 15%,#05d5ff 70%,#a6ffcb 94%) */
  /* background: linear-gradient(150deg,rgb(30, 136, 229) 15%,#05d5ff 70%,#a6ffcb 94%) */
  background: linear-gradient(150deg,#DF620D 15%,#FFAC3B 70%,#FFE596 94%)
  /* background: linear-gradient(150deg,#FFE596 15%,#FFAC3B 70%,#DF620D 94%); */

  
  
  /* background: linear-gradient(150deg,#a6ffcb 15%,#05d5ff 60%,#53f 100%) */
}

.flags-strip {
  display: inline-block; 
  margin-left: 6px;
  width:80px;
  height: 60px
}
.inputNumber:focus {
  outline: none

}

.inputNumber {
  -webkit-appearance: none;
  font-size: 20px;
  width: 30px;
  text-align: center;
  padding-bottom: 0px;
  height: 30px;  
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #999;
  padding: 0px
}

.homePage {

  position: relative;
  text-align: center;
  padding-Bottom: 500px;
  overflow: hidden;

}
.hero {
  width: 80%;
  margin: auto ;
  margin-top: 100px;
  display: inline-block;
}

ul {
  padding-left: 20px;
}

.regulamento {
  font-family: Open Sans;
  margin-left: 30px;
  width: 85%;
}

.regulamento p,li{
  font-size: 18px 
}

.sticky-table-column {
  width: unset;
}
.sticky-table-corner {
  width: unset;
}
.checkers {
  background-color: #ddd;
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='7' viewBox='0 0 8 8'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E"); */
}
.checkers .odd {
  background-color: #d5d5d5;
}

.player-row:last-child {
  padding-bottom: 50px
}

.nameColumn {
  background-color: #eee;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='7' viewBox='0 0 8 8'%3E%3Cg fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.nameSize {
  width: 170px;
  overflow: hidden;

}

.ptsColumn {
  left: 135px;
  position: absolute;
  width: 45px;
  text-align: right;
}



.column {
  display: inline-block
  
}

.tabGame {
  position: relative;
  height: 50px;
}

.gameName { 
  position: absolute;
  top: 0px;
  left: 0px; 
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
}

.gameEmail {
  position: absolute;
  left: 0px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 8px;
}
.gameUserId {
  position: absolute;
  left: 150px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 8px;
}
.gameTransactionId {
  position: absolute;
  left: 300px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 8px;
}
.gameId {
  position: absolute;
  left: 400px;
  top: 20px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 8px;
}

.results {
  position: absolute;
  left: 500px;
  top: 0px;
  font-family: Lato

}


.homeResult, .awayResult, .pts {
  width: 12px;
  font-size: 12px
}



.vertical {
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl; 
  /* text-orientation: upright; */
  vertical-align: top;
  height: 80px;
}

.tabHeader {
  margin-top: 5px;
  height: 80px;
  position: fixed;
  width: 2300px;
}

.tabContainer {
  width: 2300px
}

.tabContent {
  margin-top: 80px;
}

.sticky-table-header-wrapper { 
  position: relative;
  padding-left: 25px;
  background-color: white;
}

.sticky-table-header-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
}

.degrade {
  background: linear-gradient(150deg,rgb(30, 136, 229) 5%,rgb(255, 64, 129) 95%);
  padding-left: 25px;
}

.sidebar {
  position: absolute;
  margin-left: -25px;
  width: 25px;
  height: 60px;
}

.sidebar.odd {
  background-color: rgba(255,255,255,0.2) !important;
}

.mygames-row:last-child {
  padding-bottom: 15px
}

.whitebar {
  position: relative;
  /* background: white;  */
  /* height: 80px; */

}

.whitebar:after {
 content: "";
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 z-index: 1;
 box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);

}


.sticky-table-column {
  /* background-image:url(background_gs.svg); */
  /* background: linear-gradient(150deg,rgb(30, 136, 229) 15%,#05d5ff 70%,#a6ffcb 94%); */
  /* background: linear-gradient(150deg,rgb(30, 136, 229) 15%,#05d5ff 70%,#a6ffcb 94%); */
  /* background: linear-gradient(150deg,#DF620D 15%,#FFAC3B 70%,#FFE596 94%); */
  /* background: linear-gradient(150deg,#FFE596 15%,#FFAC3B 70%, #DF620D 94%); */
  /* background: linear-gradient(150deg,#fedf00 15%,#009b3a 70%, rgb(30, 136, 229) 94%); */
  /* background: linear-gradient(150deg,black 15%,#009b3a 70%, rgb(30, 136, 229) 94%); */
  /* background: linear-gradient(150deg,white 15%, rgb(30, 136, 229) 94%); */
  /* background: linear-gradient(150deg,white 15%,rgb(77, 208, 225) rgb(30, 136, 229) 94%); */
  /* background: linear-gradient(150deg,rgb(30, 136, 229) 20%, rgb(77, 208, 225) 50%, #eee 90%); */
  background: linear-gradient(150deg,rgb(30, 136, 229) 5%,rgb(255, 64, 129) 95%);

  

  background-color: none !important;
  padding-left: 24px;
  /* border-right: 1px solid rgb(30, 136, 229); */
  padding-right: 1px;
  color: #333;
  /* color: white */
}

.sticky-table-x-wrapper {
  background-color:white;
}

.row-0 {
  background-color: #f6f6f6;
}



.abutton  {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .025em;
  color: #6772e5;
  text-decoration: none;
  transition: all .15s ease;
  /* background: #3ecf8e; */
  text-shadow: 0 1px 3px rgba(36,180,126,.4);
  background: linear-gradient(150deg,rgb(30, 136, 229) 15%,#05d5ff 70%,#a6ffcb 94%)
  

  /* background: linear-gradient(150deg,#DF620D 15%,#FFAC3B 70%,#FFE596 94%) */


  
}


.arrow {
  background: url(/static/media/arrow.78db0f1f.svg) no-repeat top left; 
  background-size: contain;
  width: 40px;
  height: 40px;
}

.position {
 color: white;
 position: absolute;
 top: 24px;
 margin-left: -22px;
 font-family: Lato;
 font-size: 15px;
 text-align: right;
 display: inline-block;
 width: 20px;
 -webkit-transform: rotate(270deg);
         transform: rotate(270deg); 
}


.position.onedigit {
  -webkit-transform: none;
          transform: none; 
  top: 9px;
  margin-left: -24px;
}

.position.twodigits {margin-left: -24px; top: 18px}

.position sup{
  font-size: 10px;
  /* margin-top: -2px; */
  font-weight: normal !important; 
}


.position.pins {
  top: 29px;
  font-size: 15px;

}
.position.pins {top:24px;}
.position.pins.onedigit {top:9px;}
.position.pins.twodigits {top:14px;}


.ranking-pin {
  font-size: 20px !important;
  color: rgb(150,150,150) !important;
  cursor: pointer;
  margin-top: -12px;
}

.ranking-pin-white {
  font-size: 20px !important;
  color: white !important;
  cursor: pointer;
  margin-top: -12px;
  
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.bids-flags {
  width: 80px;
  height: 60px;
  box-shadow: 0 0 2px rgba(0,0,0,.14), 0 2px 4px rgba(0,0,0,.28);
}

.leaderboard-flags {
  width: 32px;
  height: 24px;
  box-shadow: 0 0 2px rgba(0,0,0,.14), 0 2px 4px rgba(0,0,0,.28);
}

.viz-flags {
  width: 128px;
  height: 96px;
  box-shadow: 0 0 1px rgba(0,0,0,.14), 0 2px 4px rgba(0,0,0,.28);
}


.f-ru {background: url(/static/media/ru.519f233e.svg) no-repeat top left; background-size: contain}
.f-qa {background: url(/static/media/qa.afe5a1c2.svg) no-repeat top left; background-size: contain}
.f-ec {background: url(/static/media/ec.4dae761c.svg) no-repeat top left; background-size: contain}
.f-ca {background: url(/static/media/ca.46482a1e.svg) no-repeat top left; background-size: contain}
.f-cm {background: url(/static/media/cm.7e6294f8.svg) no-repeat top left; background-size: contain}
.f-gh {background: url(/static/media/gh.a727698c.svg) no-repeat top left; background-size: contain}
.f-nl {background: url(/static/media/nl.84600bca.svg) no-repeat top left; background-size: contain}
.f-us {background: url(/static/media/us.ecdc9c9e.svg) no-repeat top left; background-size: contain}
.f-sa {background: url(/static/media/sa.b3f0ecc3.svg) no-repeat top left; background-size: contain}
.f-eg {background: url(/static/media/eg.7459922f.svg) no-repeat top left; background-size: contain}
.f-uy {background: url(/static/media/uy.fb1289c8.svg) no-repeat top left; background-size: contain}
.f-ma {background: url(/static/media/ma.aa50603f.svg) no-repeat top left; background-size: contain}
.f-ir {background: url(/static/media/ir.33b7bde8.svg) no-repeat top left; background-size: contain}
.f-pt {background: url(/static/media/pt.07f887f2.svg) no-repeat top left; background-size: contain}
.f-es {background: url(/static/media/es.f5311bf6.svg) no-repeat top left; background-size: contain}
.f-fr {background: url(/static/media/fr.9a5dcb2a.svg) no-repeat top left; background-size: contain}
.f-au {background: url(/static/media/au.013b1790.svg) no-repeat top left; background-size: contain}
.f-ar {background: url(/static/media/ar.a51613f0.svg) no-repeat top left; background-size: contain}
.f-is {background: url(/static/media/is.2d4bc19a.svg) no-repeat top left; background-size: contain}
.f-pe {background: url(/static/media/pe.b1cff325.svg) no-repeat top left; background-size: contain}
.f-dk {background: url(/static/media/dk.54fcc3ec.svg) no-repeat top left; background-size: contain}
.f-hr {background: url(/static/media/hr.0c71df06.svg) no-repeat top left; background-size: contain}
.f-ng {background: url(/static/media/ng.500aadeb.svg) no-repeat top left; background-size: contain}
.f-cr {background: url(/static/media/cr.60df4193.svg) no-repeat top left; background-size: contain}
.f-rs {background: url(/static/media/rs.b1128f39.svg) no-repeat top left; background-size: contain}
.f-de {background: url(/static/media/de.94f33d9b.svg) no-repeat top left; background-size: contain}
.f-mx {background: url(/static/media/mx.2728f57a.svg) no-repeat top left; background-size: contain}
.f-br {background: url(/static/media/br.233d308c.svg) no-repeat top left; background-size: contain}
.f-ch {background: url(/static/media/ch.097b4d1b.svg) no-repeat top left; background-size: contain}
.f-se {background: url(/static/media/se.0d817a1d.svg) no-repeat top left; background-size: contain}
.f-kr {background: url(/static/media/kr.2832b73a.svg) no-repeat top left; background-size: contain}
.f-be {background: url(/static/media/be.3428c9f0.svg) no-repeat top left; background-size: contain}
.f-pa {background: url(/static/media/pa.02e797ac.svg) no-repeat top left; background-size: contain}
.f-tn {background: url(/static/media/tn.ce78613a.svg) no-repeat top left; background-size: contain}
.f-co {background: url(/static/media/co.bfda7e86.svg) no-repeat top left; background-size: contain}
.f-jp {background: url(/static/media/jp.6a8f4d54.svg) no-repeat top left; background-size: contain}
.f-pl {background: url(/static/media/pl.0ca345e7.svg) no-repeat top left; background-size: contain}
.f-sn {background: url(/static/media/sn.e875d4ee.svg) no-repeat top left; background-size: contain}
.f-gb-eng {background: url(/static/media/gb-eng.f337dc2c.svg) no-repeat top left; background-size: contain}
.f-gb-wls {background: url(/static/media/gb-wls.f8b16c80.svg) no-repeat top left; background-size: contain}


.addtocard-bubble 
{
position: absolute;
pointer-events: none;
width: 160px;
height: 75px;
padding: 15px;
padding-top: 10px;
background: rgba(0, 0, 0, 0.90);
border-radius: 8px;
color: #ccc;
font-family: Lato;
/* font-weight: bold; */
font-size: 12px;
right: 2000px;
top: 75px;
z-index: 9000;
opacity: 1;

-webkit-animation-name: slide, fadeOut;
-webkit-animation-duration: 0.5s, 2s;
-webkit-animation-delay: 2s, 15s;
-webkit-animation-fill-mode: forwards, forwards;

animation-name: slide, fadeOut;
animation-duration: 0.5s, 2s;
animation-delay: 2s, 15s;
animation-fill-mode: forwards, forwards;

}

.addtocard-bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 10px 10px;
  border-color:rgba(0, 0, 0, 0.90) transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -10px;
  left: 90px;
}

.addtocard-bubble.remove {
  width: 110px;
  height: 55px;
  top: 70px;
} 

.addtocard-bubble.remove:after {
  left: 87px;

} 

@media only screen and (max-width: 513px) {
  .addtocard-bubble {
    top: 75px;
  }

  .addtocard-bubble:after {
    left: 90px;
  }

  .addtocard-bubble.remove:after {
    left: 86px;
  
  } 
    
}

@media only screen and (max-width: 345px) {
  .groupbox {
    padding-right: 10px !important;
  }
  .grouprow {
    margin-left: 10px !important;
  }
  .addtocard-bubble {
    top: 75px;
  }

  .addtocard-bubble:after {
    left: 125px;
  }

  .addtocard-bubble.remove:after {
    left: 110px;
  
  } 
    
}








.newgame-bubble 
{
position: absolute;
pointer-events: none;
width: 205px;
height: 60px;
padding: 15px;
padding-top: 10px;
background: rgba(0, 0, 0, 0.90);
border-radius: 8px;
color: #ccc;
font-family: Lato;
/* font-weight: bold; */
font-size: 12px;
right: 2000px;
top: 75px;
z-index: 9000;
opacity: 1;

-webkit-animation-name: slide, fadeOut;
-webkit-animation-duration: 0.5s, 2s;
-webkit-animation-delay: 2s, 15s;
-webkit-animation-fill-mode: forwards, forwards;

animation-name: slide, fadeOut;
animation-duration: 0.5s, 2s;
animation-delay: 2s, 15s;
animation-fill-mode: forwards, forwards;

}



.newgame-bubble:after 
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 10px 10px;
border-color:rgba(0, 0, 0, 0.90) transparent;
display: block;
width: 0;
z-index: 1;
top: -10px;
left: 185px;
}




.agdopagto-bubble 
{
position: absolute;
pointer-events: none;
width: 70px;
height: 42px;
padding: 15px;
padding-top: 10px;
background: rgba(0, 0, 0, 0.90);
border-radius: 8px;
color: #ccc;
font-family: Lato;
font-size: 12px;
right: 75px;
top: -15px;
z-index: 9000;
opacity: 0;

-webkit-animation-name: fadeIn, fadeOut;
-webkit-animation-duration: 0.5s, 3s;
-webkit-animation-delay: 2s, 8s;
-webkit-animation-fill-mode: forwards, forwards;

animation-name: fadeIn, fadeOut;
animation-duration: 0.5s, 3s;
animation-delay: 2s, 8s;
animation-fill-mode: forwards, forwards;

}



.agdopagto-bubble:after 
{
content: '';
position: absolute;
border-style: solid;
border-width: 10px 0px 10px 10px;
margin-top: -10px;
margin-right: -10px;
border: 10px solid transparent;
border-left-color: rgba(0, 0, 0, 0.90);
display: block;
width: 0;
z-index: 1;
top: 35px;
left: 98px;

}





@-webkit-keyframes slide {
  100% { right: 5px; }
}

@keyframes slide {
  100% { right: 5px; }
}

@-webkit-keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}


@-webkit-keyframes fadeIn {
  100% { opacity: 1; }
}

@keyframes fadeIn {
  100% { opacity: 1; }
}








.scroll-left {
  overflow: hidden;
  position: relative;
  font-size: 20px;
  color: white;
  width: 100%;
  /* height: 50px; */
  font-family: Roboto Condensed;

}
  
  .scroll-left  {


      
  position: absolute;
  /* top:100px; */
  margin: 0;
  line-height: 50px;
  text-align: center;
  /* Starting position */
  -webkit-transform:translateX(100%); 
  transform:translateX(100%);
  /* Apply animation to this element */
  -webkit-animation: scroll-left 10s linear infinite;
  animation: scroll-left 10s linear infinite;
  }
  /* Move it (define the animation) */
  @-webkit-keyframes scroll-left {
  0% { -webkit-transform:  translateX(100%); }
  100% { -webkit-transform:  translateX(-100%); }
  }
  @keyframes scroll-left {
  0% { /* Browser bug fix */
  -webkit-transform:  translateX(100%); /* Browser bug fix */
  transform:  translateX(100%); 
  }
  100% { /* Browser bug fix */
  -webkit-transform:   translateX(-100%); /* Browser bug fix */
  transform:  translateX(-100%); 
  }
  }
