.scroll-left {
  overflow: hidden;
  position: relative;
  font-size: 20px;
  color: white;
  width: 100%;
  /* height: 50px; */
  font-family: Roboto Condensed;

}
  
  .scroll-left  {


      
  position: absolute;
  /* top:100px; */
  margin: 0;
  line-height: 50px;
  text-align: center;
  /* Starting position */
  -moz-transform:translateX(100%);
  -webkit-transform:translateX(100%); 
  transform:translateX(100%);
  /* Apply animation to this element */ 
  -moz-animation: scroll-left 10s linear infinite;
  -webkit-animation: scroll-left 10s linear infinite;
  animation: scroll-left 10s linear infinite;
  }
  /* Move it (define the animation) */
  @-moz-keyframes scroll-left {
  0% { -moz-transform:  translateX(100%); }
  100% { -moz-transform:  translateX(-100%); }
  }
  @-webkit-keyframes scroll-left {
  0% { -webkit-transform:  translateX(100%); }
  100% { -webkit-transform:  translateX(-100%); }
  }
  @keyframes scroll-left {
  0% { 
  -moz-transform:  translateX(100%); /* Browser bug fix */
  -webkit-transform:  translateX(100%); /* Browser bug fix */
  transform:  translateX(100%); 
  }
  100% { 
  -moz-transform: translateX(-100%); /* Browser bug fix */
  -webkit-transform:   translateX(-100%); /* Browser bug fix */
  transform:  translateX(-100%); 
  }
  }