
.addtocard-bubble 
{
position: absolute;
pointer-events: none;
width: 160px;
height: 75px;
padding: 15px;
padding-top: 10px;
background: rgba(0, 0, 0, 0.90);
-webkit-border-radius: 8px;
-moz-border-radius: 8px;
border-radius: 8px;
color: #ccc;
font-family: Lato;
/* font-weight: bold; */
font-size: 12px;
right: 2000px;
top: 75px;
z-index: 9000;
opacity: 1;

-webkit-animation-name: slide, fadeOut;
-webkit-animation-duration: 0.5s, 2s;
-webkit-animation-delay: 2s, 15s;
-webkit-animation-fill-mode: forwards, forwards;

animation-name: slide, fadeOut;
animation-duration: 0.5s, 2s;
animation-delay: 2s, 15s;
animation-fill-mode: forwards, forwards;

}

.addtocard-bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 0 10px 10px;
  border-color:rgba(0, 0, 0, 0.90) transparent;
  display: block;
  width: 0;
  z-index: 1;
  top: -10px;
  left: 90px;
}

.addtocard-bubble.remove {
  width: 110px;
  height: 55px;
  top: 70px;
} 

.addtocard-bubble.remove:after {
  left: 87px;

} 

@media only screen and (max-width: 513px) {
  .addtocard-bubble {
    top: 75px;
  }

  .addtocard-bubble:after {
    left: 90px;
  }

  .addtocard-bubble.remove:after {
    left: 86px;
  
  } 
    
}

@media only screen and (max-width: 345px) {
  .groupbox {
    padding-right: 10px !important;
  }
  .grouprow {
    margin-left: 10px !important;
  }
  .addtocard-bubble {
    top: 75px;
  }

  .addtocard-bubble:after {
    left: 125px;
  }

  .addtocard-bubble.remove:after {
    left: 110px;
  
  } 
    
}








.newgame-bubble 
{
position: absolute;
pointer-events: none;
width: 205px;
height: 60px;
padding: 15px;
padding-top: 10px;
background: rgba(0, 0, 0, 0.90);
-webkit-border-radius: 8px;
-moz-border-radius: 8px;
border-radius: 8px;
color: #ccc;
font-family: Lato;
/* font-weight: bold; */
font-size: 12px;
right: 2000px;
top: 75px;
z-index: 9000;
opacity: 1;

-webkit-animation-name: slide, fadeOut;
-webkit-animation-duration: 0.5s, 2s;
-webkit-animation-delay: 2s, 15s;
-webkit-animation-fill-mode: forwards, forwards;

animation-name: slide, fadeOut;
animation-duration: 0.5s, 2s;
animation-delay: 2s, 15s;
animation-fill-mode: forwards, forwards;

}



.newgame-bubble:after 
{
content: '';
position: absolute;
border-style: solid;
border-width: 0 10px 10px;
border-color:rgba(0, 0, 0, 0.90) transparent;
display: block;
width: 0;
z-index: 1;
top: -10px;
left: 185px;
}




.agdopagto-bubble 
{
position: absolute;
pointer-events: none;
width: 70px;
height: 42px;
padding: 15px;
padding-top: 10px;
background: rgba(0, 0, 0, 0.90);
-webkit-border-radius: 8px;
-moz-border-radius: 8px;
border-radius: 8px;
color: #ccc;
font-family: Lato;
font-size: 12px;
right: 75px;
top: -15px;
z-index: 9000;
opacity: 0;

-webkit-animation-name: fadeIn, fadeOut;
-webkit-animation-duration: 0.5s, 3s;
-webkit-animation-delay: 2s, 8s;
-webkit-animation-fill-mode: forwards, forwards;

animation-name: fadeIn, fadeOut;
animation-duration: 0.5s, 3s;
animation-delay: 2s, 8s;
animation-fill-mode: forwards, forwards;

}



.agdopagto-bubble:after 
{
content: '';
position: absolute;
border-style: solid;
border-width: 10px 0px 10px 10px;
margin-top: -10px;
margin-right: -10px;
border: 10px solid transparent;
border-left-color: rgba(0, 0, 0, 0.90);
display: block;
width: 0;
z-index: 1;
top: 35px;
left: 98px;

}





@-webkit-keyframes slide {
  100% { right: 5px; }
}

@keyframes slide {
  100% { right: 5px; }
}

@-webkit-keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}


@-webkit-keyframes fadeIn {
  100% { opacity: 1; }
}

@keyframes fadeIn {
  100% { opacity: 1; }
}







